import axios from 'axios'

var API_URL = ''

if (location.hostname === 'localhost') {
    API_URL = 'http://127.0.0.1:8000/api/v1'
} else {
    API_URL = 'https://apitest.aristocrazytryon.com/api/v1'
}

// const API_KEY = "rVuzkmQ1.2BgvXL8knrPKFgLrWhywFzhTGHo8DIl5"

const instance = axios.create({
    baseURL: API_URL,
    maxBodyLength: Infinity,
    headers: {
        Authorization: 'Api-Key rVuzkmQ1.2BgvXL8knrPKFgLrWhywFzhTGHo8DIl5',
        'Content-Type': 'application/json',
    },
})

export default instance
