export default [
    {
        path: '/retail',
        name: 'HomeRetail',
        component: () => import('@/views/Retail/HomeRetail.vue'),
    },

    {
        path: '/retail/video',
        name: 'VideoRetail',
        component: () => import('@/views/Retail/VideoRetail.vue'),
    },
    {
        path: '/retail/category',
        name: 'CategoryRetail',
        component: () => import('@/views/Retail/CategoryRetail.vue'),
    },

    {
        path: '/retail/catalog',
        name: 'CatalogRetail',
        component: () => import('@/views/Retail/CatalogRetail.vue'),
        props: true,
    },

    {
        path: '/retail/ubication',
        name: 'UbicationRetail',
        component: () => import('@/views/Retail/UbicationRetail.vue'),
        props: true,
    },

    {
        path: '/retail/camera',
        name: 'CameraRetail',
        component: () => import('@/views/Retail/CameraRetail.vue'),
        props: true,
    },

    {
        path: '/retail/result',
        name: 'ResultRetail',
        component: () => import('@/views/Retail/ResultRetail.vue'),
        props: true,
    },

    {
        path: '/retail/ubication/edit',
        name: 'UbicationEditRetail',
        component: () => import('@/views/Retail/UbicationEditRetail.vue'),
        props: true,
    },

    // Otras rutas de la aplicación
]
