<template>
	<div></div>
</template>
  
 <script>
import { defineComponent } from 'vue'

import ImageService from '../../services/image.service'

export default defineComponent({
	name: 'HomeQRView',
	components: {},
	data() {
		return {
			loading: true,
			referenceQR: this.$route.params.referenceQR,
		}
	},
	methods: {
		async start() {
			this.loading = true
			this.error = false
			console.log(this.referenceQR)
			if (this.referenceQR != null && this.referenceQR != undefined) {
				await this.getReferenceQR(this.referenceQR)
			} else {
				this.error = true
			}

			this.loading = false
		},

		async getReferenceQR(reference) {
			var response = await ImageService.getSessionImage({
				reference: reference,
			})
			console.log('getReferenceQR', response.data)
			if (response.status == 200 && response.data) {
				localStorage.setItem('referenceQR', response.data.UUID)

				if (response.data.input_img) {
					localStorage.setItem('input_img', response.data.input_img)
				}
				if (response.data.url_merchant) {
					localStorage.setItem(
						'url_merchant',
						response.data.url_merchant
					)
				}

				if (response.data.products_selected) {
					localStorage.setItem(
						'products_selected',
						response.data.products_selected
					)
				}

				if (response.data.current_product) {
					localStorage.setItem(
						'current_product',
						response.data.current_product
					)
				}

				if (response.data.current_index) {
					localStorage.setItem(
						'current_index',
						response.data.current_index
					)
				}

				if (response.data.current_ubication) {
					localStorage.setItem(
						'current_ubication',
						response.data.current_ubication
					)
				}

				if (response.data.current_category) {
					localStorage.setItem(
						'current_category',
						response.data.current_category
					)
				}

				if (response.data.current_product) {
					this.$router.push({ name: 'CameraMobile' })
				} else {
					this.$router.push({ name: 'CatalogMobile' })
				}
			}
		},
	},
	created() {
		localStorage.clear()
		this.start()
	},
})
</script>
  
  