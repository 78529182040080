import api from './api'

class ImageService {
    async processImagePoints(data) {
        return await api
            .post('/process/image/points/', data)
            .then((response) => {
                return { status: response.status, data: response.data }
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                }
            })
    }

    async processImageFinal(data) {
        return await api
            .post('/process/image/final/', data)
            .then((response) => {
                return { status: response.status, data: response.data }
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                }
            })
    }

    async createSessionImage(data) {
        return await api
            .post('/session/image/create/', data)
            .then((response) => {
                return { status: response.status, data: response.data }
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                }
            })
    }

    async getSessionImage(data) {
        return await api
            .post('/session/image/single/', data)
            .then((response) => {
                return { status: response.status, data: response.data }
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                }
            })
    }

    async updateSessionImage(data) {
        return await api
            .post('/session/image/update/', data)
            .then((response) => {
                return { status: response.status, data: response.data }
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                }
            })
    }

    async getSession(UUID) {
        return await api
            .get('/session/image/single/' + UUID + '/')
            .then((response) => {
                return { status: response.status, data: response.data }
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                }
            })
    }

    async updateSession(UUID, data) {
        return await api
            .post('/session/image/update/' + UUID, data)
            .then((response) => {
                return { status: response.status, data: response.data }
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                }
            })
    }

    async downloadImage(UUID) {
        return await api
            .get('process/image/download/' + UUID + '/')
            .then((response) => {
                return { status: response.status, data: response.data }
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                }
            })
    }
}

export default new ImageService()
