export default [
    {
        path: '/web',
        name: 'HomeWeb',
        component: () => import('@/views/Web/HomeWeb.vue'),
        props: true,
    },

    // Otras rutas de la aplicación
]
