<template>
	<div id="main">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			inactivityTimer: null,
		}
	},

	methods: {
		resetInactivityTimer() {
			clearTimeout(this.inactivityTimer)
			this.inactivityTimer = setTimeout(() => {
				// Comprobar si la ruta actual está bajo '/retail'
				if (this.$route.path.startsWith('/retail')) {
					this.$router.push('/retail/video')
				}
			}, 1800000) // 60000 ms = 1 minuto
		},
		initializeInactivityTimer() {
			// Agregar eventos para varias interacciones
			document.addEventListener('mousemove', this.resetInactivityTimer)
			document.addEventListener('keypress', this.resetInactivityTimer)
			document.addEventListener('touchstart', this.resetInactivityTimer)
			// ... cualquier otro evento que consideres como actividad del usuario

			// Iniciar el temporizador por primera vez
			this.resetInactivityTimer()
		},
	},
	created() {
		this.initializeInactivityTimer()
	},
	beforeUnmount() {
		// Limpiar el temporizador y los eventos al destruir el componente
		clearTimeout(this.inactivityTimer)
		document.removeEventListener('mousemove', this.resetInactivityTimer)
		document.removeEventListener('keypress', this.resetInactivityTimer)
		document.removeEventListener('touchstart', this.resetInactivityTimer)
		// ... cualquier otro evento que hayas agregado
	},
	watch: {
		'$route.path'(newPath) {
			// Si la nueva ruta no está bajo '/retail', limpiar el temporizador
			if (!newPath.startsWith('/retail')) {
				clearTimeout(this.inactivityTimer)
			} else {
				// Si la nueva ruta está bajo '/retail', reiniciar el temporizador
				this.resetInactivityTimer()
			}
		},
	},
}
</script>

<style>
#main {
	min-height: 100dvh;
	background: #fff;
}
</style>
