<template>
	<div
		class="d-flex flex-column align-items-center justify-content-center p-5"
		style="min-height: 100dvh"
	>
		<img
			src="@/assets/img/logos/logo_aristocrazy_retail.png"
			alt="Logo"
			class="logo mb-5"
			style="width: 200px"
		/>
		<img
			:src="image_download_url"
			style="width: 100%; max-width: 300px"
			class="mb-5"
		/>

		<h1 class="mb-4">Descargar imagen</h1>
		<!-- Asumiendo que 'image_download_url' es la URL del endpoint que genera la imagen -->
		<input
			type="email"
			class="mb-3"
			style="width: 100%; height: 50px; padding: 10px 20px"
			placeholder="Introduce tu email"
		/>
		<div class="mb-3" style="text-align: left; width: 100%">
			<input
				type="checkbox"
				id="privacyPolicyCheckbox"
				v-model="privacyPolicyAccepted"
				class="me-2"
			/>
			<label for="privacyPolicyCheckbox"
				>He leído y acepto la política de privacidad</label
			>
		</div>

		<a
			:href="image_download_url"
			download="Aristocrazy_Virtual_TryOn.png"
			style="width: 100%"
		>
			<button
				style="
					background: #000;
					padding: 20px 30px;
					border-radius: 0;
					font-size: 16px;

					width: 100%;
				"
				class="btn text-uppercase text-white"
				:disabled="loading"
			>
				Descargar Imagen
			</button>
		</a>
	</div>
</template>
  
 <script>
import { defineComponent } from 'vue'

import ImageService from '../../services/image.service'

export default defineComponent({
	name: 'DownloadImage',
	components: {},
	data() {
		return {
			loading: true,
			sessionUUID: this.$route.params.sessionUUID,
			image_download_url: null,
			privacyPolicyAccepted: false,
		}
	},
	methods: {
		async start() {
			this.loading = true
			await this.getSession()
			this.loading = false
		},

		async getSession() {
			var response = await ImageService.getSession(this.sessionUUID)
			console.log('session', response.data)

			if (response.status == 200) {
				await this.downloadImage()
			}
		},

		async downloadImage() {
			var response = await ImageService.downloadImage(this.sessionUUID)
			this.image_download_url = response.data.image_base64
			// console.log('image', this.image_download_url)
		},
	},
	created() {
		this.start()
	},
})
</script>
  
  