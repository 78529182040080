import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index'
import i18n from './i18n'

require('./index')

window.$ = window.jQuery = require('jquery')

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

import VueQRCodeComponent from 'vue-qrcode-component'

import '@google/model-viewer'

import DraggableResizableVue from 'draggable-resizable-vue3'

const app = createApp(App)
app.use(router)

app.use(DraggableResizableVue)
app.use(i18n)
app.component('qr-code', VueQRCodeComponent)

app.mount('#app')
