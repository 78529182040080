export default [
    {
        path: '/mobile',
        name: 'HomeMobile',
        component: () => import('@/views/Mobile/HomeMobile.vue'),
    },

    {
        path: '/mobile/ubication',
        name: 'UbicationMobile',
        component: () => import('@/views/Mobile/UbicationMobile.vue'),
        props: true,
    },

    {
        path: '/mobile/catalog',
        name: 'CatalogMobile',
        component: () => import('@/views/Mobile/CatalogMobile.vue'),
        props: true,
    },

    {
        path: '/mobile/camera',
        name: 'CameraMobile',
        component: () => import('@/views/Mobile/CameraMobile.vue'),
        props: true,
    },

    {
        path: '/mobile/result',
        name: 'ResultMobile',
        component: () => import('@/views/Mobile/ResultMobile.vue'),
        props: true,
    },

    {
        path: '/mobile/ubication/edit',
        name: 'UbicationEditMobile',
        component: () => import('@/views/Mobile/UbicationEditMobile.vue'),
        props: true,
    },

    // Otras rutas de la aplicación
]
