import { createWebHistory, createRouter } from 'vue-router'

// QR Views
import HomeQRView from '@/views/qr/HomeQRView.vue'
import DownloadImage from '@/views/qr/DownloadImage.vue'

// Importa las rutas de app.js
import RetailRoutes from '@/router/routes/retail'
import MobileRoutes from '@/router/routes/mobile'
import WebRoutes from '@/router/routes/web'

const routes = [
    {
        path: '/qr/:referenceQR',
        name: 'HomeQRView',
        component: HomeQRView,
        beforeEnter: () => {
            localStorage.setItem('page', 'HomeQRView')
        },
    },
    {
        path: '/download/image/:sessionUUID',
        name: 'DownloadImage',
        component: DownloadImage,
        beforeEnter: () => {
            localStorage.setItem('page', 'DownloadImage')
        },
    },
]

routes.push(...RetailRoutes)
routes.push(...MobileRoutes)
routes.push(...WebRoutes)

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
})

// Guardia global para verificar la ruta
router.beforeEach((to, from, next) => {
    // Obtén la primera parte del path
    const firstPathSegment = to.path.split('/')[1]
    if (firstPathSegment === 'retail') {
        // Hacer algo específico si la ruta es "/retail"
        // Por ejemplo, redireccionar o ejecutar una función
        // console.log('Estás en la ruta /retail')
        next()
    } else if (firstPathSegment === 'qr' || firstPathSegment === 'download') {
        next()
    } else {
        // Hacer algo diferente si no es "/retail"
        // Por ejemplo, redireccionar a otra ruta o continuar con la navegación
        // console.log('No estás en la ruta /retail')
        // Obtener los parámetros de consulta actuales
        const queryParams = new URLSearchParams(window.location.search)
        // Construir la nueva ruta con los mismos parámetros de consulta
        let newPath = screen.width <= 760 ? '/mobile' : '/web'
        newPath += `?${queryParams.toString()}`

        if (
            (screen.width <= 760 && firstPathSegment !== 'mobile') ||
            (screen.width > 760 && firstPathSegment !== 'web')
        ) {
            console.log(`Redirigiendo a ${newPath}`)
            next(newPath)
        } else {
            next()
        }
    }
})

export default router
